import {
	decorateSections as decorateSectionsBase,
	loadCSS,
} from 'scripts/aem.js';
import { CODE_BASE_PATH } from 'shared/constants.ts';

const LEFT_RAIL_BLOCKS = ['author-left-rail', 'related-links-left-rail'];

export function decorateSections(main: HTMLElement) {
	decorateSectionsBase(main);

	let loadStyles = false;
	[...main.querySelectorAll(':scope > .section')].forEach((section) => {
		let blocks: Element[] = [];

		[...section.children].forEach((sectionChild) => {
			const block = sectionChild.firstElementChild;
			if (!block || block.classList.length === 0) return;

			if (LEFT_RAIL_BLOCKS.find((b) => [...block.classList].includes(b))) {
				blocks = [...blocks, block];
			}
		});

		if (blocks.length > 0) {
			loadStyles = true;

			const leftRailSection = document.createElement('div');
			leftRailSection.className = 'section section__left-rail';
			leftRailSection.dataset.sectionStatus = 'initialized';
			leftRailSection.style.display = 'none';
			blocks.forEach((leftRailBlock) =>
				leftRailSection.append(leftRailBlock.parentElement!),
			);
			section.before(leftRailSection);
		}
	});

	if (loadStyles) {
		(async () => {
			await loadCSS(`${CODE_BASE_PATH}/styles/left-rail.css`);
		})().catch((error) => {
			throw error;
		});
	}
}
