import { events } from '@dropins/tools/event-bus.js';
import {
	sampleRUM,
	decorateBlocks,
	decorateButtons,
	decorateIcons,
	getMetadata,
	loadBlocks,
	loadCSS,
	loadFooter,
	loadHeader,
	waitForLCP,
} from 'scripts/aem.js';
import {
	CODE_BASE_PATH,
	STORED_TOP_HAT_PREFERENCE_KEY,
} from 'shared/constants.ts';
import { buildAutoBlocks } from 'utils/buildAutoBlocks.ts';
import { decorateSections } from 'utils/decorateSections.ts';
import { decorateTemplateAndTheme } from 'utils/decorateTemplateAndTheme.ts';
import { getLanguageFromPath } from 'utils/languageUtils.ts';
import { initializeAuth } from './commerce/auth.ts';
import { registerCommerceDropins } from './commerce/register.ts';
import initializeDropins from './dropins.ts';

const LCP_BLOCKS = ['product-details']; // add your LCP blocks to the list

/**
 * load fonts.css and set a session storage flag
 */
async function loadFonts() {
	await loadCSS(`${CODE_BASE_PATH}/styles/fonts.css`);
	try {
		if (!window.location.hostname.includes('localhost'))
			sessionStorage.setItem('fonts-loaded', 'true');
	} catch (e) {
		// do nothing
	}
}

/**
 * Decorates the main element.
 * @param {Element} main The main element
 *
 * Declaring here rather than using @shiftparadigm/eds-core/utils/decorateMain
 * as we want to exclude the buildAutoBlocks function.
 */
export function decorateMain(main, isFragment = false) {
	decorateTemplateAndTheme();
	decorateButtons(main);
	decorateIcons(main);
	buildAutoBlocks(main, isFragment);
	decorateSections(main);
	decorateBlocks(main);
}

// If top-hat has been hidden by user, add top-hat-hidden class to header
function tryHideTopHat() {
	const topHat = getMetadata('top-hat');
	if (!topHat) {
		document.querySelector('header')?.classList.add('top-hat-hidden');
		return;
	}

	const topHatHidden = window.localStorage.getItem(
		`${STORED_TOP_HAT_PREFERENCE_KEY}-${topHat.replaceAll('/', '-')}`,
	);

	if (topHatHidden) {
		document.querySelector('header')?.classList.add('top-hat-hidden');
		return;
	}
}

/**
 * Loads everything needed to get to LCP.
 * @param {Element} doc The container element
 */
async function loadEager(doc) {
	initializeAuth();
	await initializeDropins();
	const main = doc.querySelector('main');
	if (main) {
		decorateMain(main);
		tryHideTopHat();
		document.body.classList.add('appear');

		await waitForLCP(LCP_BLOCKS);
	}

	events.emit('eds/lcp');

	try {
		/* if desktop (proxy for fast connection) or fonts already loaded, load fonts.css */
		if (window.innerWidth >= 900 || sessionStorage.getItem('fonts-loaded')) {
			loadFonts();
		}
	} catch (e) {
		// do nothing
	}
}

/**
 * Loads everything that doesn't need to be delayed.
 * @param {Element} doc The container element
 */
async function loadLazy(doc) {
	registerCommerceDropins();
	const main = doc.querySelector('main');

	await loadBlocks(main);

	loadCSS(`${CODE_BASE_PATH}/styles/lazy-styles.css`);
	loadFonts();

	sampleRUM('lazy');
	sampleRUM.observe(main.querySelectorAll('div[data-block-name]'));
	sampleRUM.observe(main.querySelectorAll('picture > img'));

	await import('utils/linkedDataSchema/linkedDataSchema.ts');

	// Lazily import files that don't need to execute until now. Improves performance.
	(async () => {
		const { loadTopHat } = await import('blocks/top-hat/top-hat.ts');
		await loadTopHat();
		loadHeader(doc.querySelector('header'));
		loadFooter(doc.querySelector('footer'));

		const { autolinkModals } = await import('utils/autoLinkModals.ts');
		autolinkModals(doc);

		const { heroLazy } = await import('blocks/hero/hero.ts');
		heroLazy(main);

		const { buildImageSliderBlocks } = await import(
			'utils/buildImageSliderBlocks.ts'
		);
		buildImageSliderBlocks(main);

		const { buildVideoModals } = await import('utils/buildVideoModals.ts');
		buildVideoModals(main);

		const { hash } = window.location;
		const element = hash ? doc.getElementById(hash.substring(1)) : false;
		if (hash && element) element.scrollIntoView();
	})();
}

/**
 * Loads everything that happens a lot later,
 * without impacting the user experience.
 */
function loadDelayed() {
	// eslint-disable-next-line import/no-cycle
	// eslint-disable-next-line @typescript-eslint/no-misused-promises
	window.setTimeout(() => import('./delayed.js'), 3000);
	// load anything that can be postponed to the latest here
}

function setLanguageInDocument() {
	const lang = getMetadata('content-language') || getLanguageFromPath();

	document.documentElement.setAttribute('lang', lang);

	try {
		const dir =
			getMetadata('content-direction') ||
			new Intl.Locale(lang)?.textInfo?.direction ||
			'ltr';
		document.documentElement.setAttribute('dir', dir);
	} catch (e) {
		console.log('Invalid or missing locale:', e);
	}
}

async function loadPage() {
	setLanguageInDocument();
	await loadEager(document);
	await loadLazy(document);
	loadDelayed();
}

loadPage();
