import { showToastV2 } from 'blocks/toast/toast.ts';
import { fetchPlaceholders } from 'scripts/aem.js';
import { loginRedirect } from 'scripts/commerce/auth.ts';
import { SESSION_EXPIRED_PARAM } from 'shared/constants.ts';

export async function showSessionExpiredToast() {
	const searchParams = new URLSearchParams(window.location.search);
	const referrerLangParam = searchParams.get(SESSION_EXPIRED_PARAM);
	if (!referrerLangParam) return;

	searchParams.delete(SESSION_EXPIRED_PARAM);
	const url = new URL(window.location.href);
	url.search = searchParams.toString();
	window.history.replaceState({}, '', url.toString());

	const placeholders = await fetchPlaceholders();

	const { message, actionLabel } = {
		message:
			placeholders.sessionExpiredToast ||
			'Your session has expired. Please login to continue.',
		actionLabel: placeholders.sessionExpiredToastActionLabel || 'Login.',
	};

	showToastV2(document.body, 'announcement', message, {
		actionLabel,
		actionOnClick: () => {
			loginRedirect().catch((error) =>
				console.error('Error logging in', error),
			);
		},
		customClass: 'toast__session-expired',
	});
}
