import { buildBlock } from 'scripts/aem.js';
import { getMetadata } from 'scripts/aem.js';
import { div } from 'scripts/dom-helpers.js';

function buildBlogAutoBlocks(main: Element) {
	const h1 = main.querySelector('h1');
	const picture = main.querySelector('picture');
	if (h1) {
		let blogHeaderElements: Element[] = [h1];
		if (picture) {
			if (
				picture.parentElement?.previousElementSibling === h1 ||
				picture.parentElement?.nextElementSibling === h1
			) {
				blogHeaderElements = [...blogHeaderElements, picture];
			}
		}
		const blogHeaderSection = document.createElement('div');
		blogHeaderSection.append(
			buildBlock('blog-header', { elems: blogHeaderElements }),
		);
		main.prepend(blogHeaderSection);
	}

	main.querySelectorAll('p').forEach((p) => {
		if (p.querySelector('picture')) {
			p.classList.add('blog--image-container');
		}
	});
}

function buildBreadcrumbsShare(main: Element) {
	const showBreadcrumbs = getMetadata('breadcrumbs').toLowerCase() === 'true';
	const showShare = getMetadata('share').toLowerCase() === 'true';

	if (!showBreadcrumbs && !showShare) {
		return;
	}

	// Make breadcrumb-share the last block to be loaded with a placeholder to avoid CLS
	main.prepend(div({ class: 'breadcrumb-placeholder' }));
	main.append(div(buildBlock('breadcrumbs-share', '')));
}

export function buildAutoBlocks(main: Element, isFragment: boolean) {
	const templates = getMetadata('template')
		.toLowerCase()
		.split(',')
		.map((c) => c.trim().replace(' ', '-'));

	if (templates.find((t) => t === 'blog')) {
		buildBlogAutoBlocks(main);
	}

	// Must be called after buildBlogAutoBlocks
	if (!isFragment) buildBreadcrumbsShare(main);
}
