import { getMetadata, loadCSS, toClassName } from 'scripts/aem.js';

/**
 * Set template (page structure) and theme (page styles).
 * Load stylesheet for template or theme if one exists
 * in `/styles/<template|theme>/<name>.css`
 */
export function decorateTemplateAndTheme() {
	const addClasses = (classes: string, type: 'template' | 'theme') => {
		classes
			.toLowerCase()
			.split(',')
			.map((c) => c.trim().replace(' ', '-'))
			.forEach((className) => {
				document.body.classList.add(toClassName(className));
				(async () => {
					await loadCSS(`/styles/${type}/${className}.css`);
				})().catch(() => {
					console.info(`No styles found for ${type} "${className}"`);
				});
			});
	};

	const template = getMetadata('template');
	if (template) addClasses(template, 'template');
	const theme = getMetadata('theme');
	if (theme) addClasses(theme, 'theme');
}
